import axios, { AxiosPromise } from 'axios'

import {
  BGPSession,
  Elan,
  IpBandwidth,
  LAG,
  LoaDocument,
  Location,
  SearchResultsLocationSearchResult,
  MetricsResponse,
  MetricsTypesResponse,
  Port,
  Prefix,
  Product,
  Project,
  ServiceComponent,
  VLAN,
  PeeringDBSizeResponse,
} from '@/lib/definitions/api.types'
import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'
import {
  ProductComponentIdentifiers,
  ProductIdentifiers,
} from '@/lib/definitions/types'
import {
  GeoCircle,
  GeoRectangle,
} from '@/pages/ServiceWizard/ServiceWizard.types'

export class ServiceService {
  getServices({
    serviceId,
    serviceComponentId,
    productId,
  }: {
    serviceId?: string | number
    serviceComponentId?: string | number
    productId?: ProductIdentifiers | ProductIdentifiers[]
  }): AxiosPromise<Array<any>> {
    const urlParams = []
    if (serviceId) urlParams.push(`service_id=${serviceId}`)
    if (serviceComponentId)
      urlParams.push(`service_component_id=${serviceComponentId}`)
    if (productId) urlParams.push(`product_id=${productId}`)

    return axios.get(
      apiUrl(
        `/api/v1/service/${
          urlParams.length > 0 ? '?' + urlParams.join('&') : ''
        }`
      ),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getService(serviceId: string | number): AxiosPromise<any> {
    return axios.get(
      apiUrl(`/api/v1/service/${serviceId}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getAggregatedCommitServiceIDs(serviceId: string | number): AxiosPromise<{portal_ids: string[], sids: string[]}> {
    return axios.get(
      apiUrl(`/api/v1/service/aggregated-commit/${serviceId}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getServiceComponentMetrics(
    portId: string | number,
    kind: string,
    step?: number,
    start?: string,
    end?: string
  ): AxiosPromise<{ data: [number, string][] }> {
    const params = [`step=${step ?? 30}`]
    if (!!start) params.push(`start=${start}`)
    if (!!end) params.push(`end=${end}`)

    return axios.get(
      apiUrl(`/api/v1/metric/${portId}/${kind}?${params.join('&')}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getServiceMetrics(
    serviceId: string | number,
    kind: string,
    timeframe?: string,
    step?: number
  ): AxiosPromise<MetricsResponse> {
    const params = [`step=${step ?? 1}`]
    if (!!timeframe) params.push(`timeframe=${timeframe}`)

    return axios.get(
      apiUrl(`/api/v1/metric/service/${serviceId}/${kind}?${params.join('&')}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getServiceMetricTypes(
    product_id: string | undefined,
    service_id: number | undefined
  ): AxiosPromise<MetricsTypesResponse> {
    const urlParams = []
    if (product_id) urlParams.push(`product_id=${product_id}`)
    if (service_id) urlParams.push(`service_id=${service_id}`)
    return axios.get(
      apiUrl(`/api/v1/metric/types?${urlParams.join('&')}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getAsnStatistics(asn?: number): AxiosPromise<any> {
    const urlParams = []
    if (asn) urlParams.push(`asn=${asn}`)
    return axios.get(
      apiUrl(`/api/v1/metric/asn-statistics${urlParams.length > 0 ? '?' + urlParams.join('&') : ''}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getServiceComponents({
    serviceId,
    excludeDependency,
    pco,
    dependencyId,
  }: {
    serviceId?: string | number
    excludeDependency?: boolean
    pco?: ProductComponentIdentifiers | ProductComponentIdentifiers[]
    dependencyId?: string | number
  }): AxiosPromise<
    | Port[]
    | LAG[]
    | BGPSession[]
    | VLAN[]
    | Prefix[]
    | IpBandwidth[]
    | Elan[]
    | ServiceComponent[]
  > {
    const urlParams = []

    if (serviceId) urlParams.push(`service_id=${serviceId}`)
    if (pco) urlParams.push(`product_component_id=${pco}`)
    if (excludeDependency !== undefined)
      urlParams.push(`exclude_dependency=${excludeDependency}`)
    if (dependencyId) urlParams.push(`dependency_id=${dependencyId}`)
    return axios.get(
      apiUrl(`/api/v1/service-component/?${urlParams.join('&')}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getUsedVlans(serviceId: string | number): AxiosPromise<VLAN[]> {
    return axios.get(
      apiUrl(`/api/v1/service-component/vlans/used?service_id=${serviceId}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getServiceComponent(serviceComponentId: string | number): AxiosPromise<Port> {
    return axios.get(
      apiUrl(`/api/v1/service-component/${serviceComponentId}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getLocations(queryParams?: {
    productId?: number | string
  }): AxiosPromise<Location[]> {
    const { productId } = queryParams || {}
    const urlParams = []

    if (productId) urlParams.push(`product_id=${productId}`)

    return axios.get(
      apiUrl(
        `/api/v1/location/${
          !!urlParams.length ? `?${urlParams.join('&')}` : ''
        }`
      ),
      getAxiosRequestConfigWithHeaders()
    )
  }

  searchLocations(queryParams?: {
    text?: string
    name?: string
    country?: string
    withinCircle?: GeoCircle
    withinRectangle?: GeoRectangle
  }): AxiosPromise<SearchResultsLocationSearchResult> {
    const { text, name, country, withinCircle, withinRectangle } =
      queryParams || {}
    const urlParams = []

    if (text) urlParams.push(`text=${text}`)
    if (name) urlParams.push(`name=${name}`)
    if (country) urlParams.push(`country=${country}`)
    if (withinCircle) {
      urlParams.push(
        `within_circle=${withinCircle.center.lat},${withinCircle.center.lng},${withinCircle.radius}`
      )
    }
    if (withinRectangle) {
      urlParams.push(
        `within_rectangle=${withinRectangle.southWest.lat},${withinRectangle.southWest.lng},${withinRectangle.northEast.lat},${withinRectangle.northEast.lng}`
      )
    }
    return axios.get(
      apiUrl(
        `/api/v1/location/search${
          !!urlParams.length ? `?${urlParams.join('&')}` : ''
        }`
      ),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getProducts(): AxiosPromise<Product[]> {
    return axios.get(
      apiUrl('/api/v1/product/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getProjects(): AxiosPromise<Project[]> {
    return axios.get(
      apiUrl('/api/v1/project/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getLoaDocuments({
    serviceId,
  }: {
    serviceId?: string | number
  }): AxiosPromise<LoaDocument[]> {
    const urlParams = []
    if (serviceId) urlParams.push(`service_id=${serviceId}`)
    return axios.get(
      apiUrl(
        `/api/v1/loa-document/${
          urlParams.length > 0 ? '?' + urlParams.join('&') : ''
        }`
      ),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getAsSetSize(
    sizes: string[]
  ): AxiosPromise<{ [key: string]: PeeringDBSizeResponse }> {
    return axios.get(
      apiUrl(`/api/v1/as-info/as-set/size?as_set=${sizes}`),
      getAxiosRequestConfigWithHeaders()
    )
  }
}
