import {
  LAG,
  Port,
  Service,
  ServiceComponent,
} from '@/lib/definitions/api.types'
import { ProductIdentifiers } from '@/lib/definitions/types'
import { ServiceService } from '@/lib/services/ServiceService'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const serviceService = new ServiceService()

const ServiceDetailsPageViaServiceId = () => {
  const { serviceId = '' } = useParams()
  const [isError, setIsError] = useState(false)
  const navigate = useNavigate()

  const redirectToServiceComponentDetails = (
    serviceComponent: ServiceComponent | LAG | Port
  ) => {
    if (serviceComponent.response_type === 'LAG') {
      return navigate(`/services/lag/${serviceComponent.id}`)
    }
    if (serviceComponent.response_type === 'Port') {
      return navigate(`/services/port/${serviceComponent.id}`)
    }
  }

  const redirectToServiceDetails = (service: Service) => {
    if (!service) return
    switch (service.product.pid) {
      case ProductIdentifiers.IP_TRANSIT:
        return navigate(`/services/ip-transit/${service.id}`)
      case ProductIdentifiers.IP_ACCESS:
        return navigate(`/services/ip-access/${service.id}`)
      case ProductIdentifiers.FLEX_ETHERNET_ENDPOINT:
      case ProductIdentifiers.LAYER2:
        return navigate(`/services/flex-ethernet/${service.id}`)
      case ProductIdentifiers.DDOS_PROTECTION:
        return navigate(`/services/ddos-protection/${service.id}`)
      case ProductIdentifiers.EXTENSION:
        return navigate(`/services/extensions/${service.id}`)
      default:
        console.warn('Unknown service type:', service.response_type)
        setIsError(true)
    }
  }

  useEffect(() => {
    if (!serviceId) return
    if (serviceId.toLowerCase().includes('sco')) {
      serviceService.getServiceComponent(serviceId).then((serviceComponent) => {
        redirectToServiceComponentDetails(serviceComponent.data)
      })
    } else {
      serviceService.getService(serviceId).then((service) => {
        redirectToServiceDetails(service.data)
      })
    }
  }, [serviceId])

  return isError ? <div>Service not found</div> : <div>Loading...</div>
}

export default ServiceDetailsPageViaServiceId
